import { AuthGuard } from './route-guards/auth-guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import {
    RouterModule,
    Routes,
    PreloadingStrategy,
    PreloadAllModules,
    CanActivate,
    ɵROUTER_PROVIDERS,
} from '@angular/router';

import { TakeSurveyComponent } from './take-survey/take-survey.component';
import { TakeSurveyOldComponent } from './take-survey-old/take-survey-old.component';
import { SearchComponent } from './search/search.component';
import { KioskComponent } from './kiosk/kiosk.component';

export const appRoutes: Routes = [
    // { path: '**', redirectTo: 'login' },
    { path: '', component: DashboardComponent, pathMatch: 'full', canActivate: [AuthGuard] },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'search',
        component: SearchComponent,
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard]
    },

    {
        path: 'accounts',
        loadChildren: './accounts/accounts.module#AccountsModule',
        canLoad: [AuthGuard]
    },
    {
        path: 'reports',
        loadChildren: './reports/reports.module#ReportsModule',
        canLoad: [AuthGuard]
    },
    {
        path: 'survey',
        loadChildren: './survey/survey.module#SurveyModule',
        canLoad: [AuthGuard]
    },
    {
        path: 'client',
        loadChildren: './clients/clients.module#ClientsModule',
        canLoad: [AuthGuard]
    }, {
        path: 'users',
        loadChildren: './users/users.module#UsersModule',
        canLoad: [AuthGuard]
    },
    {
        path: 'participants',
        loadChildren: './participants/participants.module#ParticipantsModule',
        canLoad: [AuthGuard]
    },

    { path: 'kiosk/:id', component: KioskComponent },

    { path: 'd/:id', component: TakeSurveyOldComponent },

    { path: ':id', component: TakeSurveyComponent }
    
   
];
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            useHash: false, preloadingStrategy: PreloadAllModules,
            enableTracing: false,
            onSameUrlNavigation: 'reload',
            scrollPositionRestoration: 'enabled'})
    ],
    exports: [
        RouterModule
    ]

})
export class AppRoutingModule { }
