import { Component, ChangeDetectorRef } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { AppService } from './app.service';
import Swal from 'sweetalert2';
import { ViewportScroller } from '@angular/common';
import { filter, delay } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public showSidebar = false;
  public accountType: string = '';
  constructor(private router: Router, private appService: AppService,
    private cd: ChangeDetectorRef,
    private viewportScroller: ViewportScroller) { 
    this.router.events
      .pipe(
        filter((e: any): e is Scroll => e instanceof Scroll),
        delay(0),
      )
      .subscribe(e => {
        if (e.position) {
          viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          viewportScroller.scrollToAnchor(e.anchor);
        } else {
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }

  openSideBar() {
    this.accountType = localStorage.getItem('AccountType'); 
    let token = localStorage.getItem('token')
    if (token === null || token === undefined) {
      this.showSidebar = false;
    } else {
      this.showSidebar = true;
    }

    if (this.router.url === '/login') {
      this.showSidebar = false;
    }
  }

  logout() {
    Swal.fire({
      position: 'center',
      allowOutsideClick: false,
      allowEscapeKey: false,
      title: 'Logout',
      type: 'warning',
      text: 'Are you sure to logout?',
      showCancelButton: true,
      cancelButtonColor: '#d33',
      confirmButtonText: 'ok'
    }).then((result) => {
      if (result.value) {
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    });
  }
}
