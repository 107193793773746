import { Injectable } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Subject, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable()
export class SurveyService {
    private tempinvitationCode = new Subject<string>();
    public tempinvitationCode$ = this.tempinvitationCode.asObservable();

    invitationCode(inviCode: string) {
        this.tempinvitationCode.next(inviCode);
    }

    private apiUrl = '';
    constructor(private httpService: HttpClient, private sharedService: SharedService) {
        this.apiUrl = this.sharedService.getAPIUrl();
    }

    // this method returns list of survey
    public getSurveyList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetAll', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public deleteClientReport(data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        let body = JSON.stringify(data);
        return this.httpService.post(this.apiUrl + '/api/Surveys/DeleteSurveyReports', body, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    DeleteSurveyInstructions(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Surveys/DeleteSurveyInstructions?id=' + id, httpOptions).pipe(retry(1), catchError(this.handleError));
    }


    public copySurvey(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Surveys/CopySurvey?id=' + id, httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    public getAllClientReport(uid): Observable<any[]> {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get<any[]>(this.apiUrl + '/api/Surveys/GetAllSurveyReports?surveyUId=' + uid, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public GetAccessList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetAccessList', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // this method returns a particular survey by it's id
    public getSurvey(uId) {
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetByUId?uId=' + uId)
            .pipe(retry(1), catchError(this.handleError));
    }



    // API for checking link id present of not / survey submitted or not.

    public isLinkIdPresent(presentId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Surveys/IsLinkIdPresent?id=' + presentId, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // this method adds a survey
    public addSurvey(survey) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(survey);
        return this.httpService.post(this.apiUrl + '/api/Surveys/Post', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // this method adds a survey question
    public addSurveyQuestion(questionObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(questionObj);
        return this.httpService.post(this.apiUrl + '/api/SurveyQuestionSets/AddQuestion', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    public publishSurvey(publishsurvey) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(publishsurvey);
        return this.httpService.post(this.apiUrl + '/api/Surveys/PublishSurvey', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public closeSurvey(closesurvey) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(closesurvey);
        return this.httpService.post(this.apiUrl + '/api/Surveys/CloseSurvey', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }



    public updateQuestion(questionObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(questionObj);
        return this.httpService.post(this.apiUrl + '/api/SurveyQuestionSets/UpdateQuestion', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public updateQuestionSet(questionObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(questionObj);
        return this.httpService.post(this.apiUrl + '/api/SurveyQuestionSets/UpdateQuestionSet', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public getSurveyQuestionSet(uid) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/SurveyQuestionSets/GetBySurveyUId?uId=' + uid, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    public getSurveyQuestionSetbylink(linkId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/SurveyQuestionSets/GetBySurveyLinkId?id=' + linkId, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    // this method adds a client to the data base
    public addClient(client) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(client);
        return this.httpService.post(this.apiUrl + '/api/Clients/Post', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    public UploadSurveyFile(uId) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(uId);
        return this.httpService.post(this.apiUrl + '/api/Surveys/UploadSurveyFile', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // for invitation code
    public isInvitationCodePresent(sUId, icode) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Surveys/IsInvitationCodePresent?sUId=' + sUId + '&invitationCode=' + icode, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    makeFileRequest(surveyUId) {
        // const headers = new Headers();
        // headers.append('Authorization', 'Bearer ' + localStorage.getItem('token'));
        return new Promise((resolve, reject) => {
            const downloadRequest = new XMLHttpRequest();
            downloadRequest.open('GET', this.apiUrl + '/api/Surveys/GetUsersForSurvey?sUId=' + surveyUId, true);
            downloadRequest.setRequestHeader('Content-Type', 'application/json');
            downloadRequest.responseType = 'blob';
            downloadRequest.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
            downloadRequest.onreadystatechange = function () {
                if (downloadRequest.readyState === 4) {
                    if (downloadRequest.status === 200) {
                        resolve(downloadRequest.response);
                    } else {
                        // alert('File not Found, No participant added.');
                        Swal.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            position: 'center',
                            title: 'Oops...',
                            text: 'File not Found, No participant added!',
                            type: 'error'
                        });
                        reject(downloadRequest.response);
                    }
                }
            };
            downloadRequest.send();
        });
    }

    DownloadRawResponse(surveyUId) {
        return new Promise((resolve, reject) => {
            const downloadRequest = new XMLHttpRequest();
            downloadRequest.open('GET', this.apiUrl + '/api/SurveyQuestionSetResponses/DownloadRawResponse?uId=' + surveyUId, true);
            downloadRequest.setRequestHeader('Content-Type', 'application/json');
            downloadRequest.responseType = 'blob';
            downloadRequest.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('token'));
            downloadRequest.onreadystatechange = function () {
                if (downloadRequest.readyState === 4) {
                    if (downloadRequest.status === 200) {
                        resolve(downloadRequest.response);
                    } else {
                        // alert('File not Found');
                        Swal.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            position: 'center',
                            title: 'Oops...',
                            text: 'File not found, response not available!',
                            type: 'error'
                        });
                        reject(downloadRequest.response);
                    }
                }
            };
            downloadRequest.send();
        });
    }

    public UpdateSurvey(survey) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Surveys/UpdateSurvey', survey, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    // this method returns all client list
    public getClientList() {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Clients/GetAll', httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }


    public SendInvitationEmail(surveyInvEmailObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Surveys/SendInvitationEmail', surveyInvEmailObj, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public SendReminderEmail(surveyRemiEmailObj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Surveys/SendReminderEmail', surveyRemiEmailObj, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public deleteSurvey(id) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.post(this.apiUrl + '/api/Surveys/DeleteByUId?uId=' + id,{},
             httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    public deleteQuestion(deleteobj) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            }),
            body: deleteobj
        };
        return this.httpService.delete(this.apiUrl + '/api/SurveyQuestionSets/DeleteQuestion', httpOptions).pipe(retry(1), catchError(this.handleError));
    }

    private handleError(error: HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,
            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(error);
    }

    public getAllSurveyInstructions(uid) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        return this.httpService.get(this.apiUrl + '/api/Surveys/GetAllSurveyInstructions?surveyUId=' + uid, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }

    public AddSurveyInstruction(data) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            })
        };
        const formData = JSON.stringify(data);
        return this.httpService.post(this.apiUrl + '/api/Surveys/AddOrUpdateSurveyInstruction', formData, httpOptions)
            .pipe(retry(1), catchError(this.handleError));
    }
}
